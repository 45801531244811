import { SignedIn, UserButton } from '@clerk/remix';
import { If } from '@naamdeo/react-lib/control-flow/If';
import { Button } from '@naamdeo/shadcn-ui/components/button';
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '@naamdeo/shadcn-ui/components/tooltip';
import { Typography } from '@naamdeo/shadcn-ui/components/typography';
import { Loader2Icon, SaveIcon, TriangleAlertIcon } from 'lucide-react';
import { SheetMenu } from '~/components/admin-panel/sheet-menu';

interface NavbarProps {
  title: string;
  pageIsDirty?: boolean;
  ctaText?: string;
  ctaLoading?: boolean;
  onCtaClick?: () => void;
}

export function Navbar({ title, pageIsDirty, ctaText, ctaLoading, onCtaClick }: NavbarProps) {
  return (
    <header className="sticky top-0 z-10 w-full bg-background/95 shadow backdrop-blur supports-[backdrop-filter]:bg-background/60 dark:shadow-secondary">
      <div className="mx-4 flex h-14 items-center sm:mx-8">
        <div className="flex items-center space-x-4 lg:space-x-0">
          <SheetMenu />
          <h1 className="font-bold">{title}</h1>
        </div>
        <SignedIn>
          <div className="flex flex-1 items-center justify-end gap-4">
            <If condition={pageIsDirty}>
              <TooltipProvider delayDuration={100}>
                <Tooltip>
                  <TooltipTrigger>
                    <TriangleAlertIcon color="orange" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <Typography>Remember to save your changes!</Typography>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </If>
            <If condition={Boolean(ctaText)}>
              <Button onClick={onCtaClick} size="sm">
                <If condition={ctaLoading}>
                  <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
                </If>
                <If condition={!ctaLoading}>
                  <SaveIcon className="mr-2 h-4 w-4" />
                </If>
                {ctaText}
              </Button>
            </If>
            <UserButton />
          </div>
        </SignedIn>
      </div>
    </header>
  );
}
